import { SET_NEW_PRODUCT } from '../constants';

const initialState = {};

export default function setNewProductInfo(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_NEW_PRODUCT:
      return action.payload;
    default:
      return state;
  }
}
