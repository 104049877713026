import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import authReducer from './auth';
import usersReducer from './users';
import promotionReducer from './promotion';
import customerSupportReducer from './customerSupport';
import homeCarouselReducer from './homeCarousel';
import productReducer from './productReducer';
import featuredCategoriesReducer from './featuredCategories';
import organizationDetailsReducer from './organizationDetails';
import profileReducer from './profile';
import ordersReducer from './orders';
import orderReducer from './orderReducer';
import cartItemsReducer from './cartItems';
import orderDetailReducer from './orderDetail';
import ordersOnPagination from './ordersOnPagination';
import quotesOnPagination from './quotesOnPagination';
import productSearchReducer from './productSearchReducer';
import orderHistoryReducer from './orderHistory';
import invoiceListReducer from './invoiceList';
import updateProfileReducer from './updateProfile';
import updatePasswordReducer from './updatePassword';
import registrationReducer from './registration';
import errorReducer from './errorReducer';
import countryStatesReducer from './countryStateDetails';
import setPasswordReducer from './setPassword';

import quickOrderReducer from './quickOrderReducer';
import forgotPasswordReducer from './forgotPassword';

import quoteReducer from './quotesReducer';
import subOrganizationReducer from './subOrganization';
import localeReducer from './localeReducer';
import newProductReducer from './newProductReducer';
/*
 *import authReducer from './auth.js';
 *import routerReducer from './auth.js';
 *import authorize from './auth.js';
 */

// import { combineReducers } from 'redux';

// export const AUTH_REQUEST = 'AUTH_REQUEST';
// export const AUTH_SUCCESS = 'AUTH_SUCCESS';
// export const AUTH_FAILURE = 'AUTH_FAILURE';

// export const authorize = (login, password) => ({
//   type: AUTH_REQUEST,
//   payload: { login, password },
// });

// const initialState = {
//   token: null,
//   error: null,
// };

// const authReducer = (state = initialState, { type, payload }) => {
//   switch (type) {
//     case AUTH_SUCCESS: {
//       return { ...state, token: payload };
//     }
//     case AUTH_FAILURE: {
//       return { ...state, error: payload };
//     }
//     default:
//       return state;
//   }
// };

/*
 * const reducer = combineReducers({
 *   auth: authReducer,
 *   router: routerReducer
 * });
 */

// export default reducer;

export default combineReducers({
  usersReducer,
  promotionReducer,
  customerSupportReducer,
  homeCarouselReducer,
  productReducer,
  featuredCategoriesReducer,
  organizationDetailsReducer,
  cartItemsReducer,
  auth: authReducer,
  router: routerReducer,
  profileReducer,
  ordersReducer,
  orderReducer,
  orderDetailReducer,
  productSearchReducer,
  orderHistoryReducer,
  ordersOnPagination,
  quotesOnPagination,
  invoiceListReducer,
  updateProfileReducer,
  updatePasswordReducer,
  registrationReducer,
  errorReducer,
  countryStatesReducer,
  setPasswordReducer,
  quickOrderReducer,
  forgotPasswordReducer,
  quoteReducer,
  subOrganizationReducer,
  localeReducer,
  newProductReducer,
});
