// const BASE_URL = 'https://dev-bepsy-api.objectedge.com';
const BASE_URL = 'https://ct-api.objectedge.com';
// const BASE_URL = 'https://ct-api.objectedge.com';
// const BASE_URL = 'https://bepsy-ep-api.objectedge.com';
const APPLICATION_NAME = 'cms';
const VERSION = 'v1';
const ENVIRONMENT = 'published';
// const APPLICATION_NAME_BE = 'ct';
const APPLICATION_NAME_BE = 'ct';
// const APPLICATION_NAME_BE = 'ep';

export {
  //eslint-disable-line
  BASE_URL,
  APPLICATION_NAME,
  VERSION,
  ENVIRONMENT,
  APPLICATION_NAME_BE,
};

export const API_SETTINGS_CONFIG = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest', // Tell server that it is an AJAX request.
  },
};
