import { put, takeLatest, call, select } from 'redux-saga/effects';

import { GET_PRODUCT_SAGA, SET_ERROR_STATE } from '../../constants';
import { setProducts } from '../../actions/productListingAction';
import { setNewProduct } from '../../actions/newProductAction';
import { getProducts } from '../../api/productlistingApi';

export const LOCALE = (state) => state.localeReducer;

function* workerGetProductSaga(data) {
  try {
    // const products = yield call(getProducts, data.productId);
    const locale = yield select(LOCALE);
    // TODO OUR NEW BIG PRODUCT WITH LOCALE, Stored all info in the new product
    const response = yield call(getProducts, data.productId);
    const newProduct = response.data;

    let newProductKeys = Object.keys(newProduct);
    newProductKeys = newProductKeys.filter((e) => e !== 'links');
    newProductKeys.forEach((e) => {
      newProduct[e].productList[0] &&
        newProduct[e].productList[0].compositeProducts.map((prod) => {
          prod.media.carouselMedia = [];
          prod.media.images.fullURIs.map((item) => {
            prod.media.carouselMedia.push({
              type: 'img',
              tag: 'img',
              url: item,
            });
            return item;
          });
          // prod.media.videos.map((item) => {
          //   if (!item.sourceURI.includes('.mp4' || '.webm')) {
          //     prod.media.carouselMedia.push({
          //       type: 'video',
          //       tag: 'iframe',
          //       url: item.sourceURI.includes('watch')
          //         ? item.sourceURI.replace('watch?v=', 'embed/')
          //         : item.sourceURI
          //     });
          //   } else {
          //     prod.media.carouselMedia.push({
          //       type: 'video',
          //       tag: 'video',
          //       url: item.sourceURI
          //     });
          //   }
          //   return item;
          // });
          return prod;
        });
    });
    //TODO NEW PODUCT IS SAVED IN THE STATE
    yield put(setNewProduct(newProduct));
    yield put(setProducts(newProduct[`${locale}`]));

    // products.data.productList[0] &&
    //   products.data.productList[0].compositeProducts.map((prod) => {
    //     prod.media.carouselMedia = [];
    //     prod.media.images.fullURIs.map((item) => {
    //       prod.media.carouselMedia.push({
    //         type: 'img',
    //         tag: 'img',
    //         url: item
    //       });
    //       return item;
    //     });
    //     prod.media.videos.map((item) => {
    //       if (!item.sourceURI.includes('.mp4' || '.webm')) {
    //         prod.media.carouselMedia.push({
    //           type: 'video',
    //           tag: 'iframe',
    //           url: item.sourceURI.includes('watch')
    //             ? item.sourceURI.replace('watch?v=', 'embed/')
    //             : item.sourceURI
    //         });
    //       } else {
    //         prod.media.carouselMedia.push({
    //           type: 'video',
    //           tag: 'video',
    //           url: item.sourceURI
    //         });
    //       }
    //       return item;
    //     });
    //     return prod;
    //   });
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetProductSaga() {
  yield takeLatest(GET_PRODUCT_SAGA, workerGetProductSaga);
}
