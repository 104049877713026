import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import './style.css';
import { connect } from 'react-redux';
import { keyMap } from '../../../src/utils/keyMapping';

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 1,
    },
    '&:before': {
      display: 'none',
    },
    // '&$expanded': {
    //   margin: 'auto',
    // },
    padding: 0,
  },
  // content: {
  //   padding: 0,
  // },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, .05)',
    margin: '0 20px',
    minHeight: 56,
    padding: 0,
    // '&$expanded': {
    //   minHeight: 56,
    // },
    fontWeight: 'bold',
  },
  // content: {
  //   '&$expanded': {
  //     margin: '12px 0',
  //     fontWeight: 'bold',
  //   },
  // },
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiExpansionPanelDetails);

const customizedExpansionPanel = ({
  name,
  data,
  contentType,
  locale,
}) => {
  const [isExpanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(!isExpanded);
  };
  return (
    <div className='customized-panel'>
      <ExpansionPanel
        square
        expanded={isExpanded}
        onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1d-content'
          id='panel1d-header'>
          <Typography>
            <span className='accordian-header'>{name}</span>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {/* Remove because this tag contain <p> */}
          {/* <Typography> */}
          {contentType === 'list' ? (
            <ul className='content-list'>
              {data.map((e, i) => (
                <li key={`list-${i} - ${e.spec}`}>
                  {locale === 'en-US' ? (
                    <>
                      {e.spec} : {e.specValue}
                    </>
                  ) : (
                    <>
                      {keyMap[e.spec]} : {e.specValue}
                    </>
                  )}
                </li>
              ))}
            </ul>
          ) : null}

          {contentType === 'text' ? (
            <div className='content-data'>
              {ReactHtmlParser(data)}
            </div>
          ) : null}
          {/* </Typography> */}

          {contentType === 'component' && (
            <div className='width-100'>{data}</div>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.localeReducer,
});

export default connect(mapStateToProps)(customizedExpansionPanel);
