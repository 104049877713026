import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const quantityStepper = ({
  selectedQuantity,
  quantityChangeHandler,
  stepperClickHandler,
  locale,
  skuDetails,
}) => (
  <div className='quantity-stepper-box'>
    <span className='product-quantity'>
      {locale === 'en-US' ? 'Quantity' : 'Menge'}
    </span>

    <div className='quantity-stepper'>
      <input
        type='button'
        value='-'
        className='button-minus'
        data-field='quantity'
        onClick={(e) =>
          stepperClickHandler(skuDetails, e, 'decrease')
        }
      />
      <input
        type='text'
        value={selectedQuantity}
        name='quantity'
        className='quantity-field'
        maxLength='3'
        onChange={quantityChangeHandler}
      />
      <input
        type='button'
        value='+'
        className='button-plus'
        data-field='quantity'
        onClick={(e) =>
          stepperClickHandler(skuDetails, e, 'increase')
        }
      />
    </div>
  </div>
);

quantityStepper.propTypes = {
  selectedQuantity: PropTypes.number.isRequired,
  quantityChangeHandler: PropTypes.func.isRequired,
  stepperClickHandler: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.localeReducer,
});

export default connect(mapStateToProps)(quantityStepper);
