export const keyMap = {
  'Put Lead Time': 'Setzen Sie die Vorlaufzeit ein',
  'Minimum Order Value': 'Mindestbestellwert',
  Features: 'Merkmale und Vorteile',
  'Bore Size inch': 'Bohrungsgröße (Zoll)',
  'Bore Size mm': 'Bohrungsgröße (mm)',
  'Case Material': 'Gehäusematerial',
  Compliances: 'Einhaltung der Vorschriften',
  'Configuration Type Seals': 'Konfigurationstyp (Dichtungen)',
  'Dry Running': 'Trockenlauf',
  'Lip Material': 'Lippenmaterial',
  'Lip Material Single': 'Lippenmaterial (Single)',
  'Lip Material Case Side': 'Lippenmaterial (Gehäuseseite)',
  'Lip Material Clamp Ring Side': 'Lippenmaterial (Klemmringseite)',
  'Lip Material Composition': 'Zusammensetzung des Lippenmaterials',
  'Lip Material Composition Case Side':
    'Zusammensetzung des Lippenmaterials (Gehäuseseite)',
  'Lip Material Composition Clamp Ring Side':
    'Zusammensetzung des Lippenmaterials (Klemmringseite)',
  'Dust Lip Material': 'Staub Lippenmaterial',
  'For Use With': 'Zur Verwendung mit',
  'Max Shaft Surface Speed feet min':
    'Max. Wellenoberflächengeschwindigkeit (Fuß/min)',
  'Max Shaft Surface Speed meter sec':
    'Max. Wellenoberflächengeschwindigkeit (Meter/Sek.)',
  'Max Temperature C': 'Max. Temperatur (C)',
  'Max Temperature F': 'Max. Temperatur (F)',
  'Min Temperature C': 'Mindesttemperatur (C)',
  'Min Temperature F': 'Mindesttemperatur (F)',
  'Misalignment Tolerance inch':
    'Toleranz für Fehlausrichtungen (Zoll)',
  'Misalignment Tolerance mm': 'Toleranz für Ausrichtungsfehler (mm)',
  'Preload inch': 'Vorspannung (Zoll)',
  'Pressure Mabar': 'Max. Druck (bar)',
  'Pressure Mapsi': 'Max. Druck (psi)',
  'Primary Dimensional Unit of Measure':
    'Primärdimensionale Maßeinheit',
  'Shaft Size inch': 'Schaftgröße (Zoll)',
  'Shaft Size mm': 'Wellengröße (mm)',
  'Static Seal Material': 'Statisches Dichtungsmaterial',
  'Style Model': 'Stil/Modell',
  'Support Ring Material': 'Material des Stützrings',
  'Temperature Range C': 'Temperaturbereich (C)',
  'Temperature Range F': 'Temperaturbereich (F)',
  Vaccum: 'Vakuum',
  'Width inch': 'Breite (Zoll)',
  'Width mm': 'Breite (mm)',
  'Typical Applications': 'Typische Anwendungen',
  'Media Use': 'Mediennutzung',
  'Industries Served': 'Belieferte Branchen',
  'Lip Base Material Abbreviation':
    'Abkürzung für Lippenbasismaterial',
  'Lip Base Material Name': 'Name des Lippenbasismaterials',
  'Spacers Exist': 'Abstandshalter existieren',
  'Split or Solid': 'Geteilt oder Festkörper',
  'Spring Material': 'Federmaterial',
  'Standard Special': 'Standard/Spezial',
  Branding: 'Branding',
  Color: 'Farbe',
  Composition: 'Zusammensetzung',
  Fiber: 'Faser',
  'Length inch': 'Länge (Zoll)',
  'Length mm': 'Länge (mm)',
  'Primary Nominal Thickness': 'Primäre Nenndicke',
  'Rubber Chemical Name': 'Name der Gummichemikalie',
  'Rubber Material': 'Gummimaterial',
  'Rubber Material Abbreviation': 'Abkürzung für Gummimaterial',
  'Thickness inch': 'Dicke (Zoll)',
  'Thickness mm': 'Dicke (mm)',
  'Connection Standard': 'Anschlussstandard',
  'Gasket Seal Profile': 'Dichtungs-/Dichtungsprofil',
  'Inside Diameter Inch': 'Innendurchmesser (Zoll)',
  'Inside Diameter mm': 'Innendurchmesser (mm)',
  Mass: 'Masse',
  'Material Name': 'Materialbezeichnung',
  'Outside Diameter inch': 'Außendurchmesser (Zoll)',
  'Outside Diameter mm': 'Außendurchmesser (mm)',
  'Tube Standard': 'Rohr Standard',
  'Vacuum in Hg': 'Vakuum (in. Hg)',
  'Vacuum mm Hg': 'Vakuum (mm.Hg)',
  longDescription: 'Lange Beschreibung',
  fullImageURLs: 'Vollständige Bild-URLs',
  largeImageURLs: 'Umfangreiche Bild-URLs',
  mediumImageURLs: 'URLs mittlerer Bilder',
  smallImageURLs: 'Kleine Bild-URLs',
  thumbImageURLs: 'Thumb-Bild-URLs',
  sourceImageURLs: 'Quellbild-URLs',
  primaryFullImageURL: 'Primäre vollständige Bild-URL',
  primaryLargeImageURL: 'Primäre URL für große Bilder',
  primaryMediumImageURL: 'URL für primäres mittleres Bild',
  primarySmallImageURL: 'Primäre kleine Bild-URL',
  primaryThumbImageURL: 'URL des primären Miniaturbilds',
  primarySourceImageURL: 'URL des Primärquellbilds',
  primaryImageTitle: 'Titel des Hauptbildes',
  primaryImageAltText: 'Alternativtext für das Primärbild',
};
