import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { getMiniCartItemsSaga } from '../../actions/cartItemAction';

import './Styles.css';
import Logo from '../../assets/Logo/logo.png';
import VentecLogo from '../../assets/Logo/ventec-logo.png';
import PPGLogo from '../../assets/Logo/ppg-logo.png';

import MiniCart from '../MiniCart';
import { getAvailableSites, getSiteById } from '../../api/api';
import { setLocale } from '../../../src/actions/localeAction';
import { setNewProduct } from '../../../src/actions/newProductAction';

function getCurrentContext() {
  try {
    return JSON.parse(localStorage.getItem('context'));
  } catch (err) {
    return null;
  }
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMinicart: true,
    };
  }

  componentDidMount() {
    this.props.getMiniCartItemsSaga();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      if (this.props.newProduct[`${this.props.locale}`]) {
        this.props.setProduct(
          'UPDATE_PRODUCT_AT_LOCALE',
          this.props.newProduct[`${this.props.locale}`].productList[0]
        );
      }
    }
  }

  componentWillMount() {
    if (this.props.location) {
      const { location } = this.props;
      if (location.pathname === '/quoteCheckout') {
        this.setState({
          showMinicart: false,
        });
      }
    }
  }

  render() {
    const token = localStorage.getItem('access_token');

    if (!token) {
      return <Redirect to='/login' />;
    }

    const currentContext = getCurrentContext();
    const siteId = currentContext && currentContext.siteId;

    const { order, showToggle } = this.props;
    return (
      <div className='show-minicart'>
        {/* Fixed minicart display issue */}
        {order !== null && (
          <AppBar position='static'>
            <Toolbar className='header_bg'>
              <Hidden only={['lg', 'xl']}>
                {showToggle !== 'false' && (
                  <IconButton
                    edge='start'
                    color='inherit'
                    aria-label='Menu'
                    onClick={this.props.toggleMenu}>
                    <MenuIcon />
                  </IconButton>
                )}
              </Hidden>
              <Typography variant='h6'>
                <a href='/' className={`logo-container ${siteId}`}>
                  <img src={getSiteById(siteId).logo} alt='logo' />
                </a>
              </Typography>
              <div className=''>
                <select
                  value={this.props.locale}
                  onChange={(event) => {
                    this.props.setLocaleInfo(
                      event.currentTarget.value
                    );
                  }}
                  name='locale'
                  id='locale'
                  className='locale-container'>
                  <option value='en-US'>English</option>
                  <option value='de'>Deutsch</option>
                </select>

                {this.state.showMinicart && (
                  <MiniCart order={order} />
                )}
              </div>
            </Toolbar>
          </AppBar>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  getMiniCartItemsSaga: PropTypes.func.isRequired,
  order: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  products: [state.productReducer],
  order: [state.orderReducer],
  locale: state.localeReducer,
  newProduct: state.newProductReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getMiniCartItemsSaga: () => dispatch(getMiniCartItemsSaga()),
  setLocaleInfo: (locale) => dispatch(setLocale(locale)),
  setProduct: (action, newProduct) =>
    dispatch({
      type: action,
      product: newProduct,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
