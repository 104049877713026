import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import Grid from '@material-ui/core/Grid';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import ProductImage from '../../components/ProductDetailsPage/productImage';
import ProductDetails from '../../components/ProductDetailsPage/productDetails';
import CustomizedExpansionPanel from '../../components/Shared/customizedExpansionPanel';
import {
  getProductSaga,
  clearProduct,
} from '../../actions/productListingAction';
import {
  addToCartState,
  addToCartSaga,
} from '../../actions/cartItemAction';
import ContentLoader from 'react-content-loader';
import CustomLoader from '../../components/Shared/customLoader';
import CustomCarousel from '../../components/Shared/customCarousel';
import CustomerSupport from '../CustomerSupport';
import ProductBrief from './productBrief';

import {
  ADD_TO_CART_INITIATED,
  ADD_TO_CART_DONE,
} from '../../constants';
import './style.css';

class ProductDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseVariantType: null,
      selectedColor: null,
      selectedSize: -1,
      selectedQuantity: 1,
      selectedSKU: null,
      selectedSKUDetails: null,
      showError: false,
      showSizeError: false,
      addToQuote: true,
      modelIsOpen: false,
      selectedRecmndProductId: null,
    };
  }

  componentDidMount() {
    this.props.getProductSaga();
    window.scroll(0, 0);
  }

  componentDidUpdate(prevProps) {
    this.toastManager = prevProps.toastManager;
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.getProductSaga(this.props.match.params.id);
    }
    // if (this.props.errorResponse[0] !== prevProps.errorResponse[0]) {
    //   const errorMessage = this.props.errorResponse[0];
    //   this.toastManager.add(errorMessage.message, {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   });
    //   this.props.addToCartState(null);
    // } else {
    // condition to show toast message in case of success
    // if (
    //   this.props.order[0].addToCartState !==
    //     prevProps.order[0].addToCartState &&
    //   this.props.order[0].addToCartState == ADD_TO_CART_DONE
    // ) {
    //   this.props.toastManager.add(ADD_TO_CART_DONE, {
    //     appearance: 'success',
    //     autoDismiss: true,
    //   });
    // }
    // }
  }
  componentWillUnmount() {
    this.props.addToCartState(null);
    this.props.clearProduct();
  }

  colorClickHandler = (e) => {
    if (!e) return;
    this.props.addToCartState(null);
    const selectedColor = e.target.attributes.name.value;
    //const selectedColorName = e.target.attributes.name.value;
    const selectedSKU = selectedColor;
    let product = this.props.products;
    if (product !== undefined && product[0].products.length) {
      product = product[0].products;
      const { compositeProducts } = product[0];
      compositeProducts.map((data, i) => {
        if (data.variantProperties.x_color.name === selectedColor) {
          this.setState({
            ...this.state,
            selectedColor,
            selectedSize: -1,
            selectedSKU,
            selectedSKUDetails: data,
            showError: false,
            showSizeError: false,
          });
        }
        return data;
      });
    }
  };

  sizeChangeHandler = (event) => {
    if (!event) return;
    this.props.addToCartState(null);
    const selectedSize = event.target.value;
    const selectedSKU = selectedSize;
    let product = this.props.products;
    if (product !== undefined && product[0].products.length) {
      product = product[0].products;
      const { compositeProducts } = product[0];
      compositeProducts.map((data, i) => {
        if (data.componentSku === selectedSKU) {
          this.setState({
            selectedSize,
            selectedSKU,
            selectedSKUDetails: data,
            showError: false,
            showSizeError: false,
          });
        }
        return data;
      });
    }
  };

  quantityChangeHandler = (e) => {
    this.props.addToCartState(null);
    const reg = /^[0-9\b]+$/;
    if (reg.test(e.target.value)) {
      this.setState({
        selectedQuantity: e.target.value,
        showError: false,
        showSizeError: false,
      });
    }
  };

  stepperClickHandler = (e, type) => {
    this.props.addToCartState(null);
    const quantity = parseInt(this.state.selectedQuantity);
    if (type === 'increase' && quantity < 1000) {
      this.setState({ selectedQuantity: quantity + 1 });
    } else if (type === 'decrease' && quantity !== 0) {
      this.setState({ selectedQuantity: quantity - 1 });
    }
  };

  addToQuoteClickHandler = (e) => {
    this.props.addToCartState(ADD_TO_CART_INITIATED);
    const product = this.props.products;
    let skuDetails = null;
    if (this.state.selectedSKUDetails) {
      skuDetails = this.state.selectedSKUDetails;
    } else if (
      product &&
      product[0].products &&
      product[0].products.length
    ) {
      const { compositeProducts } = product[0].products[0];
      compositeProducts.map((data, i) => {
        if (data.componentSku === product[0].products[0].SKU) {
          skuDetails = data;
        }
      });
    }
    const skuInventory = skuDetails.skuInventoryItem.qtyAvailable;
    if (this.state.selectedQuantity > parseInt(skuInventory)) {
      this.setState({ showError: true, modelIsOpen: false }, () => {
        this.props.addToCartState(null);
      });
      return;
    }
    if (
      this.state.selectedColor == null ||
      this.state.selectedColor ===
        skuDetails.variantProperties.x_color.name
    ) {
      this.props.addToCartSaga(
        skuDetails.componentSku,
        this.state.selectedQuantity
      );
    } else {
      this.setState(
        { showSizeError: true, modelIsOpen: false },
        () => {
          this.props.addToCartState(null);
        }
      );
    }
    // this.props.addToCartSaga(
    //   skuDetails.componentSku,
    //   this.state.selectedQuantity
    // );
    // this.setState({ modelIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modelIsOpen: false });
  };

  recmdProductClickHandler = (e, productId) => {
    this.props.history.push(`/product/${productId}`, {
      productId: productId,
    });
  };

  getUtilitiesOfProduct = (products) => {
    const utilitiesArray = [
      'features',
      'typicalApplication',
      'mediaUse',
      'industryServed',
    ];

    const utilitiesObj = products.productSpecs.filter((e) =>
      utilitiesArray.includes(e.name)
    );

    const utilitiesValues = utilitiesObj.map((e) => {
      return { [e.spec]: e.specValue };
    });
    return utilitiesValues;
  };

  render() {
    let productDescription = '';
    let compositeProducts = [];
    let skuDetails = this.state.selectedSKUDetails;
    let productName = '';
    let utilities = {};
    let variants = null;
    const sizeList = [];
    let proudctImgURL = null;
    let selectedSize = null;
    let selectedColor = null;
    let orderData = null;
    let addToCartState = null;
    const recommendedProducts = [];
    let productSpecification = [];

    const products =
      this.props.products !== undefined &&
      this.props.products[0].products.length
        ? this.props.products[0].products[0]
        : null;

    orderData =
      this.props.order && this.props.order[0].cart
        ? this.props.order[0].cart[0]
        : null;

    addToCartState = this.props.order
      ? this.props.order[0].addToCartState
      : null;

    if (products !== null) {
      if (products.sfdcName && products.sfdcName !== null)
        productName = products.sfdcName;
      else productName = products.alternateName;

      productDescription = products.longDesc || products.shortDesc;
      compositeProducts = products.compositeProducts;
      variants = products.variants;
      if (skuDetails === null)
        compositeProducts.map((e, i) => {
          if (e.componentSku === products.SKU) {
            skuDetails = e;
          }
        });

      const toFilterFromSpec = [
        'primaryFulImageURL',
        'Put Lead Time',
      ];

      productSpecification = products.productSpecs.filter(
        (e) => !toFilterFromSpec.includes(e.spec)
      );

      if (
        products.ERelatedProducts &&
        products.ERelatedProducts.length
      ) {
        const recmdProducts = products.ERelatedProducts;

        recmdProducts.map((data, i) => {
          const stubRecomndProducts = {
            id: '',
            name: '',
            title: '',
            imgUrl: '',
            altMsg: '',
            price: '',
          };
          stubRecomndProducts.id = data.sfid;
          stubRecomndProducts.name = data.shortDesc;
          stubRecomndProducts.title = data.sfdcName;
          stubRecomndProducts.imgUrl =
            data.compositeProducts[0].EProductMedia.smallURI;
          stubRecomndProducts.altMsg =
            data.compositeProducts[0].EProductMedia.fullAltMessage;
          stubRecomndProducts.price =
            data.compositeProducts[0].priceEntry.listPrice;
          recommendedProducts.push(stubRecomndProducts);
        });
      }
      utilities = this.getUtilitiesOfProduct(products);
    }

    return (
      <PageLayoutHOC {...this.props}>
        {products !== null && skuDetails && (
          <div>
            <ProductBrief />
            <Grid className='product-feature externalContainer'>
              {productDescription && (
                <CustomizedExpansionPanel
                  name={
                    this.props.locale === 'en-US'
                      ? 'Description:'
                      : 'Beschreibung:'
                  }
                  data={productDescription}
                  contentType='text'
                />
              )}
              {utilities['typicalApplication'] && (
                <CustomizedExpansionPanel
                  name={
                    this.props.locale === 'en-US'
                      ? 'Typical Application:'
                      : 'Typische Anwendung'
                  }
                  data={utilities['typicalApplication']}
                  contentType='text'
                />
              )}
              {utilities['mediaUse'] && (
                <CustomizedExpansionPanel
                  name={
                    this.props.locale === 'en-US'
                      ? 'Media Use:'
                      : 'Mediennutzung:'
                  }
                  data={utilities['mediaUse']}
                  contentType='text'
                />
              )}
              {utilities['industryServed'] && (
                <CustomizedExpansionPanel
                  name={
                    this.props.locale === 'en-US'
                      ? 'Industries Served:'
                      : 'Belieferte Branchen'
                  }
                  data={utilities['industryServed']}
                  contentType='text'
                />
              )}
              {utilities['features'] && (
                <CustomizedExpansionPanel
                  name={
                    this.props.locale === 'en-US'
                      ? 'Features and Benefits:'
                      : 'Merkmale und Vorteil'
                  }
                  data={utilities['features']}
                  contentType='list'
                />
              )}
              {products.productSpecs && (
                <CustomizedExpansionPanel
                  name={
                    this.props.locale === 'en-US'
                      ? 'Specification:'
                      : 'Angabe:'
                  }
                  data={productSpecification}
                  contentType='list'
                />
              )}
            </Grid>

            <CustomCarousel
              headerText='Recommended Products'
              dataList={recommendedProducts}
              btnLabel='Know More'
              clickHandler={this.recmdProductClickHandler}
            />

            <CustomerSupport />
          </div>
        )}
        {products === null && (
          <ContentLoader
            height={500}
            width={400}
            speed={2}
            primaryColor='#f3f3f3'
            secondaryColor='#ecebeb'></ContentLoader>
          // <CustomLoader />
        )}
      </PageLayoutHOC>
    );
  }
}

const mapStateToProps = (state) => ({
  products: [state.productReducer],
  order: [state.orderReducer],
  errorResponse: [state.errorReducer.error_data],
  locale: state.localeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getProductSaga: () => dispatch(getProductSaga()),
  addToCartSaga: (skuId, quantity) =>
    dispatch(addToCartSaga(skuId, quantity)),
  addToCartState: (status) => dispatch(addToCartState(status)),
  clearProduct: () => dispatch(clearProduct()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(ProductDetailsPage));
